/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    
    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! racineCustomizationsSettings ) return;
    
    var settings = racineCustomizationsSettings,
        ver = racineCustomizationsSettings.assetsVersion;

    // Scripts
    RequireBundle.register( 'form-appointment', [ settings.jsPath + 'form-appointment' + ver + '.min.js' ], '[data-appointment-form]', function(){ formAppointment.init(); } );
    RequireBundle.register( 'jewel-details', [ settings.jsPath + 'jewel-details' + ver + '.min.js' ], '[data-jewel-details-id]', function(){ jewelDetails.init(); } );
    RequireBundle.register( 'hero-auto-switch', [ settings.jsPath + 'hero-auto-switch' + ver + '.min.js' ], '.home-hero__images', function(){ HeroAutoSwitch.init(); } );

})();
